<template>
    <div>
      <c-search-box @enter="getList">
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              label="대상년도"
              type="year"
              default="today"
              v-model="searchParam.participantYear"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-select
              :comboItems="statusItems"
              type="search"
              itemText="codeName"
              itemValue="code"
              name="completeFlag"
              label="진행상태"
              v-model="searchParam.completeFlag"
            ></c-select>
          </div>
        </template>
      </c-search-box>
      <c-table
        title="만족도 조사 목록"
        :columns="grid.columns"
        :data="grid.data"
        @linkClick="linkClick"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <!-- 검색 -->
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </template>
      </c-table>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </template>
  
  <script>
  import selectConfig from '@/js/selectConfig';
  export default {
    name: 'satisfactionSurvey',
    data() {
      return {
        searchParam: {
          plantCd: null,
          userId: '',
          participantYear: '',
          completeFlag: null,
        },
        grid: {
          columns: [
            {
              name: 'plantName',
              field: 'plantName',
              label: 'LBLPLANT',
              align: 'center',
              style: 'width:100px',
              sortable: true,
            },
            {
              name: 'participantYear',
              field: 'participantYear',
              label: '대상년도',
              style: 'width:100px',
              align: 'center',
              sortable: true,
            },
            {
              name: 'participantRound',
              field: 'participantRound',
              label: '회차',
              style: 'width:80px',
              align: 'center',
              sortable: true,
            },
            {
              name: 'participantTitle',
              field: 'participantTitle',
              label: '제목',
              style: 'width:250px',
              align: 'left',
              sortable: true,
            },
            {
              name: 'userName',
              field: 'userName',
              label: '대상자',
              align: 'center',
              style: 'width: 100px',
              sortable: false,
              type: 'link',
            },
            {
              name: 'completeFlagName',
              field: 'completeFlagName',
              label: '진행상태',
              align: 'center',
              style: 'width: 100px',
              sortable: false,
            },
            {
              name: 'survey',
              field: 'survey',
              label: '설문조사',
              align: 'center',
              child: [
                { 
                  name: 'checklist1Anw',
                  field: 'checklist1Anw',
                  label: '선정 및<br>분류 적절성',
                  style: 'width: 80px',
                  sortable: false,
                },
                { 
                  name: 'checklist2Anw',
                  field: 'checklist12Anw',
                  label: '보건관리자<br>전문성',
                  style: 'width: 80px',
                  sortable: false,
                },
                { 
                  name: 'checklist3Anw',
                  field: 'checklist3Anw',
                  label: '예방도움',
                  style: 'width: 80px',
                  sortable: false,
                },
                { 
                  name: 'checklist4Anw',
                  field: 'checklist4Anw',
                  label: '시간할애<br>적정성',
                  style: 'width: 80px',
                  sortable: false,
                },
                { 
                  name: 'checklist5Anw',
                  field: 'checklist5Anw',
                  label: '통증 및<br>증상 감소',
                  style: 'width: 80px',
                  sortable: false,
                },
                { 
                  name: 'checklist7Anw',
                  field: 'checklist7Anw',
                  label: '추천 여부',
                  style: 'width: 80px',
                  sortable: false,
                },
                { 
                  name: 'checklist8Anw',
                  field: 'checklist8Anw',
                  label: '종합 만족도',
                  style: 'width: 80px',
                  sortable: false,
                },
              ]
            },
            { 
              name: 'checklistTotal',
              field: 'checklistTotal',
              label: '총점',
              style: 'width: 60px',
              sortable: false,
            },
            {
              name: 'vas',
              field: 'vas',
              label: '통증 변화',
              align: 'center',
              child: [
                { 
                  name: 'checklist6BeforeScore',
                  field: 'checklist6BeforeScore',
                  label: '치료 전',
                  style: 'width: 60px',
                  sortable: false,
                },
                { 
                  name: 'checklist6AfterScore',
                  field: 'checklist6AfterScore',
                  label: '치료 후',
                  style: 'width: 60px',
                  sortable: false,
                },
              ]
            },
            { 
              name: 'writeDate',
              field: 'writeDate',
              label: '작성일자',
              style: 'width: 100px',
              align: 'center',
              sortable: false,
            },
          ],
          data: [],
        },
        editable: true,
        listUrl: '',
        popupOptions: {
          isFull: false,
          width: '80%',
          target: null,
          title: '',
          visible: false,
          param: {},
          closeCallback: null,
        },
        statusItems: [
          { code: 'N', codeName: '작성중' },
          { code: 'Y', codeName: '설문완료' },
        ],
      };
    },
    computed: {
      isHeaDept() { 
        return this.$_.indexOf(this.$store.getters.auths, 'SAGI000026') > -1 
      },
    },
    beforeCreate() {},
    created() {},
    beforeMount() {
      Object.assign(this.$data, this.$options.data());
    },
    mounted() {
      this.init();
    },
    beforeDestroy() {
    },
    methods: {
      init() {
        // role setting
        this.editable = this.$route.meta.editable;
        // url setting
        this.listUrl = selectConfig.hea.etr.participant.survey.url;
        // code setting

        if (!this.isHeaDept) {
          this.$set(this.searchParam, 'userId', this.$store.getters.user.userId);
        }
        // list setting
        this.getList();
      },
      getList() {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      },
      /* eslint-disable no-unused-vars */
      linkClick(row) {
        this.popupOptions.title = '만족도 조사 상세';
        this.popupOptions.target = () => import(`${'./satisfactionSurveyDetail.vue'}`);
        this.popupOptions.param = {
          heaEtrParticipantStatusUserId: row.heaEtrParticipantStatusUserId,
        };
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      },
      closePopup() {
        this.popupOptions.target = null;
        this.popupOptions.visible = false;
        this.getList();
      },
    }
  };
  </script>
  